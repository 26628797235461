import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { User } from '@ui/Components/SiteNavigation/SiteNav.Component'
import { NavigationEnd, Router } from '@angular/router'

export interface SiteNavLink {
	text: string
	route: string
	icon: string
	permission?: number
}

@Component({
	selector: 'site-nav-link',
	templateUrl: './SiteNavLink.Component.html',
})
export class SiteNavLinkComponent implements OnInit {
	@Input() public link: SiteNavLink

	@Input() public user: User

	@Output() public readonly closeNav = new EventEmitter<void>()

	@Input() private readonly router: Router

	public active = false

	public ngOnInit(): void {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.active = event.url.startsWith(this.link.route)
			}
		})
	}
}
